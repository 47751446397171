<template>
  <validation-observer
    ref="logEntryForm"
  >
    <b-overlay
      :show="loading"
      :variant="overlayVariant"
      :opacity="overlayOpacity"
      rounded="sm"
    >
      <b-row class="match-height">
        <b-col
          cols="12"
        >
          <b-alert
            :show="log_not_found"
            variant="danger"
            class="mb-50"
          >
            <div class="alert-body">
              <feather-icon
                icon="XIcon"
                class="mr-50"
              />
              <span>Log Entry not found. Click <router-link :to="{ name: 'log-entries' }">here</router-link> to view log entries.</span>
            </div>
          </b-alert>
          <b-card v-if="false">
            <b-card-title>{{ selectedLoggableItemHistory.name }}</b-card-title>
            <b-card-sub-title v-if="selectedLoggableItemHistory.created_at">
              <p><strong>Submitted Date:</strong> {{ selectedLoggableItemHistory.created_at | moment('LLL') }}</p>
              <p><strong>Submitted By: </strong> {{ selectedLoggableItemHistory.user.name }} ({{ selectedLoggableItemHistory.user.email }})</p>
              <p><strong>Last Updated:</strong> {{ selectedLoggableItemHistory.updated_at | moment('LLL') }}</p>
            </b-card-sub-title>
            <b-card-body>
              <b-form
                v-if="selectedLoggableItemHistory && !log_not_found"
                @submit.prevent="submitForm"
              >
                <b-row>
                  <b-col
                    v-for="(item, index) in dailyLogQuestions"
                    :key="item.id"
                    cols="12"
                  >
                    <div v-if="item.visible">
                      <b-form-group
                        :label="item.question.text"
                        :label-for="`question${index+1}`"
                      >
                        <validation-provider
                          v-if="item.question.input_type.name === 'text' && !item.question.text.includes('Patient Number')"
                          #default="{ errors }"
                          :vid="`question${index+1}`"
                          :rules="{ required: item.required }"
                        >
                          <b-form-input
                            :id="`question${index+1}`"
                            v-model="responses[index]"
                            :state="errors.length > 0 ? false:null"
                            :class="errors.length > 0 ? 'is-invalid':null"
                            :name="`question${index+1}`"
                            data-vv-validate-on="blur"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>

                        <validation-provider
                          v-if="item.question.input_type.name === 'text' && item.question.text.includes('Patient Number')"
                          #default="{ errors }"
                          :vid="`question${index+1}`"
                          :rules="{ required: item.required }"
                        >
                          <cleave
                            v-model="responses[index]"
                            :options="cleaveOptions"
                            class="form-control"
                            :name="`question${index+1}`"
                            :state="errors.length > 0 ? false:null"
                            :class="errors.length > 0 ? 'is-invalid':null"
                            :raw="false"
                            data-vv-validate-on="blur"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>

                        <validation-provider
                          v-if="item.question.input_type.name === 'numeric'"
                          #default="{ errors }"
                          :vid="`question${index+1}`"
                          :rules="{ required: item.required }"
                        >
                          <cleave
                            v-model="responses[index]"
                            :options="numericCleaveOptions"
                            class="form-control"
                            :name="`question${index+1}`"
                            :state="errors.length > 0 ? false:null"
                            :class="errors.length > 0 ? 'is-invalid':null"
                            :raw="false"
                            data-vv-validate-on="blur"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>

                        <validation-provider
                          v-if="item.question.input_type.name === 'datetime'"
                          #default="{ errors }"
                          :vid="`question${index+1}`"
                          :rules="{ required: item.required }"
                        >
                          <flat-pickr
                            v-model="responses[index]"
                            :config="{ dateFormat: 'Z', altInput: true, altFormat: 'F j, Y h:i K', enableTime: true }"
                            :state="errors.length > 0 ? false:null"
                            :class="errors.length > 0 ? 'is-invalid':null"
                            class="form-control"
                            :name="`question${index+1}`"
                            data-vv-validate-on="blur"
                            @on-open="setMinimumDateTime"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>

                        <validation-provider
                          v-if="item.question.input_type.name === 'time'"
                          #default="{ errors }"
                          :vid="`question${index+1}`"
                          :rules="{ required: item.required }"
                        >
                          <flat-pickr
                            v-model="responses[index]"
                            :config="{ enableTime: true, noCalendar: true, dateFormat:'H:i:S',time_24hr: true, defaultHour: 0, minuteIncrement: 1, enableSeconds: true }"
                            :state="errors.length > 0 ? false:null"
                            :class="errors.length > 0 ? 'is-invalid':null"
                            class="form-control"
                            :name="`question${index+1}`"
                            data-vv-validate-on="blur"
                            @on-open="setMinimumDateTime"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>

                        <validation-provider
                          v-if="item.question.input_type.name === 'multi-select-dropdown'"
                          #default="{ errors }"
                          :vid="`question${index+1}`"
                          :rules="{ required: item.required }"
                        >
                          <v-select
                            v-model="responses[index]"
                            multiple
                            :close-on-select="false"
                            :options="item.question.option_group.option_choices"
                            :state="errors.length > 0 ? false:null"
                            :class="errors.length > 0 ? 'is-invalid':null"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :name="`question${index+1}`"
                            data-vv-validate-on="blur"
                          >
                            <span slot="no-options">{{ $t('filter_no_data') }}</span>
                          </v-select>
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>

                        <validation-provider
                          v-if="item.question.input_type.name === 'single-select'"
                          #default="{ errors }"
                          :vid="`question${index+1}`"
                          :rules="{ required: item.required }"
                        >
                          <v-select
                            v-model="responses[index]"
                            :options="item.question.option_group.option_choices"
                            :state="errors.length > 0 ? false:null"
                            :class="errors.length > 0 ? 'is-invalid':null"
                            :name="`question${index+1}`"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            data-vv-validate-on="blur"
                          >
                            <span slot="no-options">{{ $t('filter_no_data') }}</span>
                          </v-select>
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>

                        <validation-provider
                          v-if="item.question.input_type.name === 'boolean'"
                          #default="{ errors }"
                          :vid="`question${index+1}`"
                          :rules="{ required: item.required }"
                        >
                          <v-select
                            v-model="responses[index]"
                            :options="booleanOptions"
                            :state="errors.length > 0 ? false:null"
                            :class="errors.length > 0 ? 'is-invalid':null"
                            :name="`question${index+1}`"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            data-vv-validate-on="blur"
                          >
                            <span slot="no-options">{{ $t('filter_no_data') }}</span>
                          </v-select>
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>

                        <validation-provider
                          v-if="item.question.input_type.name === 'textarea'"
                          #default="{ errors }"
                          :vid="`question${index+1}`"
                          :rules="{ required: item.required }"
                        >
                          <b-form-textarea
                            v-model="responses[index]"
                            :state="errors.length > 0 ? false:null"
                            :class="errors.length > 0 ? 'is-invalid':null"
                            :name="`question${index+1}`"
                            rows="3"
                            max-rows="8"
                            data-vv-validate-on="blur"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>

                        <validation-provider
                          v-if="item.question.input_type.name === 'multi-select-modal' && filteredEntries.length >= 0"
                          #default="{ errors }"
                          :vid="`question${index+1}`"
                          :rules="{ required: item.required }"
                        >
                          <v-select
                            v-model="serviceResponses"
                            multiple
                            :disabled="filteredEntries.length === 0"
                            :close-on-select="false"
                            :options="filteredEntries"
                            :state="errors.length > 0 ? false:null"
                            :class="errors.length > 0 ? 'is-invalid':null"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :name="`question${index+1}`"
                            data-vv-validate-on="blur"
                          >
                            <span slot="no-options">{{ $t('filter_no_data') }}</span>
                          </v-select>
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>

                        <b-table
                          v-if="item.question.input_type.name === 'multi-select-modal' && serviceResponses.length > 0"
                          :fields="fields"
                          :items="serviceResponses"
                          responsive
                          sticky-header
                          striped
                          bordered
                          class="mt-2"
                        >
                          <template #cell(name)="data">
                            {{ data.item.label }}
                          </template>
                          <template #cell(gold_standard_time)="data">
                            {{ data.item.gold_standard_time.time }} ({{ unitsOfTimeFormatted(data.item.gold_standard_time.time) }})
                          </template>
                          <template #cell(actual_time_spent)="data">
                            <validation-provider
                              #default="{ errors }"
                              :vid="`actualTime${data.index}`"
                              :rules="{ required: item.required }"
                            >
                              <flat-pickr
                                v-model="serviceResponses[data.index].answer_time"
                                :config="{ enableTime: true, noCalendar: true, dateFormat:'H:i:S',time_24hr: true, defaultHour: 0, minuteIncrement: 1, enableSeconds: true }"
                                :state="errors.length > 0 ? false:null"
                                :class="errors.length > 0 ? 'is-invalid':null"
                                class="form-control"
                                :name="`actualTime${data.index}`"
                                data-vv-validate-on="blur"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </template>
                          <template #cell(performance_indicator)="data">
                            <b-badge
                              v-if="serviceResponses[data.index].answer_time && serviceResponses[data.index].answer_time != '00:00:00'"
                              :variant="getPerformanceColour(getPerformancePercentage(data.item.gold_standard_time.time,serviceResponses[data.index].answer_time))"
                            >
                              {{ getPerformanceTitle(getPerformancePercentage(data.item.gold_standard_time.time,serviceResponses[data.index].answer_time)) }}
                            </b-badge>
                          </template>
                        </b-table>

                        <b-alert
                          v-if="item.question.input_type.name === 'multi-select-modal' && serviceResponses.length > 0"
                          v-height-fade.appear
                          :show="true"
                          class="mt-1"
                          icon-pack="feather"
                          icon="icon-info"
                        >
                          <div
                            class="alert-body"
                          >
                            <feather-icon
                              icon="InfoIcon"
                              class="mr-50"
                            />
                            <span>Click <a
                              href="https://storage.googleapis.com/dentyme-ddgi/resources/blood_pressure.pdf"
                              target="_blank"
                            ><b>here</b></a> to view guidelines about Hypertension in Adults</span>
                          </div>
                        </b-alert>
                        <b-alert
                          v-if="item.question.text==='Periodontal Type' && filteredEntries.length > 0"
                          v-height-fade.appear
                          :show="true"
                          class="mt-1"
                          icon-pack="feather"
                          icon="icon-info"
                        >
                          <div
                            class="alert-body"
                          >
                            <feather-icon
                              icon="InfoIcon"
                              class="mr-50"
                            />
                            <span>Click <a
                              href="https://storage.googleapis.com/dentyme-ddgi/resources/Staging%20and%20Grading%20Periodontitis.pdf"
                              target="_blank"
                            ><b>here</b></a> to learn about Staging and Grading Periodontis</span>
                          </div>

                        </b-alert>
                        <b-alert
                          v-if="item.question.text==='Periodontal Type' && filteredEntries.length > 0"
                          v-height-fade.appear
                          :show="true"
                          class="mt-1"
                          icon-pack="feather"
                          icon="icon-info"
                        >
                          <div
                            class="alert-body"
                          >
                            <feather-icon
                              icon="InfoIcon"
                              class="mr-50"
                            />
                            <span>Click <a
                              href="https://storage.googleapis.com/dentyme-ddgi/resources/periodontal_classification_scheme.pdf"
                              target="_blank"
                            ><b>here</b></a> to learn about a classification scheme for periodontal and peri‐implant diseases and conditions</span>
                          </div>
                        </b-alert>
                        <b-alert
                          v-if="item.question.text==='Periodontal Type' && filteredEntries.length > 0"
                          v-height-fade.appear
                          :show="true"
                          class="mt-1"
                          icon-pack="feather"
                          icon="icon-info"
                        >
                          <div
                            class="alert-body"
                          >
                            <feather-icon
                              icon="InfoIcon"
                              class="mr-50"
                            />
                            <span>Click <a
                              href="https://storage.googleapis.com/dentyme-ddgi/resources/periodontitis_chart.pdf"
                              target="_blank"
                            ><b>here</b></a> to view a chart of the stages of Periodontitis</span>
                          </div>

                        </b-alert>
                      </b-form-group>
                    </div>
                  </b-col>

                  <!-- totals -->
                  <b-col
                    v-if="serviceResponses.length > 0 && activeUser.currentRole.name === 'dental_hygienist'"
                    cols="12"
                  >
                    <b-alert
                      :show="true"
                      variant="info"
                      class="mb-50"
                    >
                      <div class="alert-body">
                        <feather-icon
                          icon="InfoIcon"
                          class="mr-50"
                        />
                        <span>Total Gold Standard Time: <strong>{{ getTotalGoldStandardTime() }} ({{ unitsOfTimeFormatted(getTotalGoldStandardTime()) }})</strong></span>
                      </div>
                    </b-alert>
                  </b-col>

                  <!-- totals -->
                  <b-col
                    v-if="serviceResponses.length > 0 && activeUser.currentRole.name === 'dental_hygienist'"
                    cols="12"
                  >
                    <b-alert
                      :show="true"
                      variant="info"
                      class="mb-50"
                    >
                      <div class="alert-body">
                        <feather-icon
                          icon="InfoIcon"
                          class="mr-50"
                        />
                        <span>Total Actual Time Spent: <strong>{{ getTotalActualTime() }} ({{ unitsOfTimeFormatted(getTotalActualTime()) }})</strong></span>
                      </div>
                    </b-alert>
                  </b-col>

                  <!-- submit warning -->
                  <b-col cols="12">
                    <b-alert
                      :show="true"
                      variant="warning"
                      class="mb-50"
                    >
                      <div class="alert-body">
                        <feather-icon
                          icon="InfoIcon"
                          class="mr-50"
                        />
                        <span>{{ $t('log_entries.edit_warning') }}</span>
                      </div>
                    </b-alert>
                  </b-col>

                  <!-- submit and reset -->
                  <b-col
                    cols="12"
                    class="mt-1"
                  >
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      type="submit"
                      variant="primary"
                      class="mr-1"
                    >
                      {{ $t('buttons.submit') }}
                    </b-button>
                    <!-- <b-button
                      v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                      type="reset"
                      variant="outline-secondary"
                      @click="resetForm"
                    >
                      {{ $t('buttons.reset') }}
                    </b-button> -->
                  </b-col>
                </b-row>
              </b-form>
            </b-card-body>
          </b-card>
          <survey-sparrow
            v-else-if="surveyUrl"
            :office-id="activeUser.office_id"
            :user-id="activeUser.id"
            :user-name="activeUser.first_name"
            :loggable-item-history-id="loggableItemHistoryId"
            :iframe="true"
            :embed-token="embedToken"
            :url="surveyUrl"
          />
        </b-col>
      </b-row>
    </b-overlay>
  </validation-observer>
</template>

<script>
import {
  BCard, BCardBody, BCardTitle, BCardSubTitle, BCol, BRow, BButton, BLink, BForm, BFormGroup, BFormInput, BOverlay, BFormTextarea, BTable, BBadge, BAlert,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { mapGetters, mapActions } from 'vuex'
import {
  ValidationProvider,
  ValidationObserver,
  extend,
} from 'vee-validate'
import {
  required,
  regex,
} from '@validations'
import Cleave from 'vue-cleave-component'
import 'cleave.js/dist/addons/cleave-phone.us'
import flatPickr from 'vue-flatpickr-component'
import moment from 'moment'
import vSelect from 'vue-select'
import {
  heightFade,
} from '@core/directives/animations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

extend('required', {
  ...required,
  message: 'This field is required',
})

export default {
  components: {
    BCol,
    BRow,
    BCard,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    // eslint-disable-next-line vue/no-unused-components
    BButton,
    // eslint-disable-next-line vue/no-unused-components
    BLink,
    BForm,
    BFormGroup,
    BFormInput,
    ValidationObserver,
    ValidationProvider,
    BOverlay,
    Cleave,
    flatPickr,
    vSelect,
    BFormTextarea,
    BTable,
    BBadge,
    BAlert,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    SurveySparrow: () => import('@/views/components/surveysparrow/SurveySparrow.vue'),
  },
  directives: {
    Ripple,
    'height-fade': heightFade,
  },
  data() {
    return {
      fields: [
        { key: 'name', label: 'Name' },
        { key: 'gold_standard_time', label: 'Gold Standard Time' },
        { key: 'actual_time_spent', label: 'Actual Time Spent' },
        { key: 'performance_indicator', label: 'Performance Indicator' },
      ],
      loggableItemId: null,
      loading: false,
      overlayOpacity: 0.75,
      overlayVariant: 'white',
      responses: [],
      serviceResponses: [],
      required,
      regex,
      cleaveOptions: {
        numeral: true,
        numeralPositiveOnly: true,
        prefix: 'Patient ',
        noImmediatePrefix: true,
        numeralThousandsGroupStyle: 'none',
        numeralDecimalScale: 0,
      },
      numericCleaveOptions: {
        numeral: true,
        numeralPositiveOnly: true,
        noImmediatePrefix: true,
        numeralThousandsGroupStyle: 'none',
        numeralDecimalScale: 0,
      },
      booleanOptions: [{ label: 'Yes', value: true }, { label: 'No', value: false }],
      sanitizedResponses: [],
      actualTimeSpent: [],
      saveMode: 'final',
      documentTitle: 'Log',
      documentDate: moment().format('l'),
      log_not_found: false,
      responseIds: {},
      loggableItemHistoryId: null,
    }
  },
  computed: {
    ...mapGetters('auth', ['activeUser']),
    ...mapGetters('loggableItems', ['dailyLogQuestions', 'goldStandardTimes', 'selectedLoggableItemHistory', 'surveyUrl', 'embedToken']),
    getDefaultDate() {
      return moment().format()
    },
    today() {
      return moment().startOf('day').format()
    },
    filteredEntries() {
      let entries = []
      const found = this.dailyLogQuestions.find(q => q.question.text === 'Periodontal Type') // find the question for periodontal type
      const perioOptionIds = found.question.option_group.option_choices.map(o => o.id) // retrieve option choice ids for all periodontal types
      if (found) {
        // const response = this.responsesStep1.find(o=>o.question_id === found.question_id);
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < this.responses.length; i++) {
          if (this.responses[i] && perioOptionIds.indexOf(this.responses[i].value) > -1) {
            const perio = found.question.option_group.option_choices.find(o => o.id === this.responses[i].value)
            const serviceQuestion = this.step2.find(q => q.question.input_type.name === 'multi-select-modal')
            entries = serviceQuestion.question.option_group.option_choices.filter(o => o.gold_standard_time.periodontal_type_id === perio.periodontal_type_id)
            break
          }
        }
      }
      return entries
    },
    step1() {
      if (this.dailyLogQuestions.length > 0) {
        return this.dailyLogQuestions.filter(l => (l.question.option_group === null) || (l.question.option_group && l
          .question.option_group.name !== 'Services'))
      }
      return []
    },
    step2() {
      if (this.dailyLogQuestions.length > 0) {
        const clone = [...this.dailyLogQuestions]
        return clone.filter(l => l.question.option_group && l.question.option_group.name
            === 'Services')
      }
      return []
    },
  },
  /*
  watch: {
    // when this updated, populate the v-models for the questions
    selectedLoggableItemHistory: {
      deep: true,
      handler(val) {
        let responseValues
        if (!val.responses || (val.responses && val.responses.length === 0)) {
          responseValues = val.draft
        } else {
          responseValues = val.responses
        }
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < responseValues.length; i++) {
          const questionIndex = this.dailyLogQuestions.findIndex(q => q.question_id === responseValues[i].question_id)
          if (this.dailyLogQuestions[questionIndex].question.input_type.name === 'text' || this.dailyLogQuestions[questionIndex].question.input_type.name === 'textarea') {
            this.responses[questionIndex] = responseValues[i].answer_text
          } else if (this.dailyLogQuestions[questionIndex].question.input_type.name === 'datetime') {
            this.responses[questionIndex] = responseValues[i].answer_datetime
          } else if (this.dailyLogQuestions[questionIndex].question.input_type.name === 'boolean') {
            const selected = this.booleanOptions.find(o => o.value === responseValues[i].answer_yn)
            this.responses[questionIndex] = selected
          } else if (this.dailyLogQuestions[questionIndex].question.input_type.name === 'numeric') {
            this.responses[questionIndex] = responseValues[i].answer_numeric
          } else if (this.dailyLogQuestions[questionIndex].question.input_type.name === 'single-select') {
            const selected = this.dailyLogQuestions[questionIndex].question.option_group.option_choices.find(o => o.id === responseValues[i].option_choice_id)
            this.responses[questionIndex] = selected
          } else if (this.dailyLogQuestions[questionIndex].question.input_type.name === 'multi-select-dropdown') {
            const selected = this.dailyLogQuestions[questionIndex].question.option_group.option_choices.find(o => o.id === responseValues[i].option_choice_id)
            if (Array.isArray(this.responses[questionIndex])) {
              this.responses[questionIndex].push(selected)
            } else {
              this.responses[questionIndex] = []
              this.responses[questionIndex].push(selected)
            }
          } else if (this.dailyLogQuestions[questionIndex].question.input_type.name === 'multi-select-modal') {
            const selected = this.dailyLogQuestions[questionIndex].question.option_group.option_choices.find(o => o.id === responseValues[i].option_choice_id)
            if (responseValues[i].answer_time) {
              selected.answer_time = responseValues[i].answer_time
            }
            if (Array.isArray(this.serviceResponses)) {
              this.serviceResponses.push(selected)
            } else {
              this.serviceResponses = []
              this.serviceResponses.push(selected)
            }
          }
        }
      },
    },
  },
  */
  async created() {
    try {
      this.loading = true
      // await this.getDailyLogQuestions(this.activeUser.role_id)
      // await this.getGoldStandardTimes()
      const { loggableItemHistoryId } = this.$route.params
      this.loggableItemHistoryId = loggableItemHistoryId
      await this.getLoggableItemHistory(loggableItemHistoryId).then(async () => {
        const { can_edit } = this.selectedLoggableItemHistory
        // eslint-disable-next-line camelcase
        if (!can_edit) {
          this.log_not_found = true
        } else {
          // this.initializeModels()
          await this.setSurveyUrlAndToken({ survey_url: this.selectedLoggableItemHistory.surveysparrow_unique_survey_url, embed_token: this.selectedLoggableItemHistory.loggable_item.surveysparrow_embed_token })
        }
      })
    } catch (e) {
      console.debug(`Could not fetch data in LogEntriesEdit.vue: ${e.message}`)
      if (e && e.response && e.response.status === 404) {
        this.log_not_found = true
      }
    } finally {
      setTimeout(() => {
        this.loading = false
      }, 1500)
    }
  },
  methods: {
    ...mapActions('loggableItems', ['getDailyLogQuestions', 'getGoldStandardTimes', 'createLoggableItemHistory', 'createLoggableItemHistoryDraft', 'createResponse', 'deleteLoggableItemHistory', 'getLoggableItemHistory', 'updateLoggableItemHistory', 'deleteLoggableItemHistoryResponses', 'updateLoggableItemHistoryDraft', 'setSurveyUrlAndToken']),
    submitForm() {
      // eslint-disable-next-line consistent-return
      this.$refs.logEntryForm.validate().then(async success => {
        if (success) {
          try {
            this.loading = true
            this.sanitizedResponses = []
            this.sanitizeResponses(this.dailyLogQuestions, this.responses)
            this.sanitizeResponses(this.step2, this.serviceResponses)
            // eslint-disable-next-line no-plusplus
            for (let i = 0; i < this.sanitizedResponses.length; i++) {
              this.sanitizedResponses[i].id = this.responseIds[i]
            }
            const historyPayload = {
              id: this.selectedLoggableItemHistory.id,
              name: this.selectedLoggableItemHistory.name,
              user_id: this.selectedLoggableItemHistory.user_id,
              loggable_item_id: this.selectedLoggableItemHistory.loggable_item_id,
              submitted_at: !this.selectedLoggableItemHistory.submitted_at ? moment() : this.selectedLoggableItemHistory.submitted_at,
              loggable_item_history_draft: { draft: JSON.stringify(this.sanitizedResponses) },
              responses: this.sanitizedResponses,
            }
            // if (this.selectedLoggableItemHistory.submitted_at === null && this.saveMode !== 'draft') {
            //   historyPayload.submitted_at = moment()
            // }
            // eslint-disable-next-line no-unreachable
            await this.updateLoggableItemHistory(historyPayload)
            this.showSuccessToast('The log was successfully updated')
            setTimeout(() => {
              this.$router.push({ name: 'log-entries', replace: true })
            }, 1000)
          } catch (e) {
            console.log(`Error updating log in LogEntriesEdit.vue: ${e.message}`)
            this.showErrorToast('Oops! Could not save log. Please try again.')
          } finally {
            setTimeout(() => {
              this.loading = false
            }, 1500)
          }
        }
      })
    },
    resetForm() {
      this.initializeModels()
      this.$refs.logEntryForm.reset()
    },
    setMinimumDateTime(selectedDates, dateStr, instance) {
      if (!dateStr) {
        instance.set('minDate', this.today)
        instance.setDate(this.getDefaultDate, true)
      }
    },
    getDropdown(objects) {
      const dropdown = []
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < objects.length; i++) {
        const obj = {}
        obj.label = obj.name || obj.description || 'Test'
        obj.value = obj.id
        dropdown.push(obj)
      }
      return dropdown
    },
    saveDraft() {
      this.sanitizedResponses = []
      this.sanitizeResponses(this.dailyLogQuestions, this.responses)
      this.sanitizeResponses(this.step2, this.serviceResponses)
      this.saveMode = 'draft'
      this.$vs.dialog({
        type: 'confirm',
        color: 'warning',
        title: 'Confirm',
        text: 'Are you sure you would like to submit these entries as a draft?',
        accept: this.acceptAlert,
      })
    },
    getPerformancePercentage(goldStandardTime, actualTime) {
      const t1 = moment.duration(goldStandardTime).asSeconds()
      const t2 = moment.duration(actualTime).asSeconds()
      return (t2 / t1) * 100
    },
    getPerformanceColour(num) {
      if (num <= 100) return 'success'
      if (num > 100 && num <= 140) return 'primary'
      if (num > 140 && num <= 170) return 'warning'
      if (num > 170) return 'danger'
      return 'primary'
    },
    getPerformanceTitle(num) {
      if (num <= 100) return 'Excellent'
      if (num > 100 && num <= 140) return 'Good'
      if (num > 140 && num <= 170) return 'Satisfactory'
      if (num > 170) return 'Need to re-evaluate'
      return 'primary'
    },
    getTotalGoldStandardTime() {
      if (this.serviceResponses.length > 0) {
        const gst = this.serviceResponses.map(g => moment.duration(g.gold_standard_time.time).asSeconds())
        const sum = gst.reduce((a, b) => a + b, 0)
        return moment().startOf('day').seconds(sum).format('HH:mm:ss')
      }
      return null
    },
    getTotalActualTime() {
      if (this.serviceResponses.length > 0) {
        const ast = this.serviceResponses.map(a => moment.duration(a.answer_time).asSeconds())
        const sum = ast.reduce((a, b) => a + b, 0)
        return moment().startOf('day').seconds(sum).format('HH:mm:ss')
      }
      return null
    },
    unitsOfTime(timestamp) {
      const minutes = moment.duration(timestamp).asMinutes()
      const cursor = Math.floor(minutes / 15)
      let units = 0
      if (cursor === 0) {
        if (minutes <= 7.5) {
          units += 0.5
        } else {
          units += 1
        }
      } else {
        units = cursor
        const diff = minutes - (15 * cursor)
        if (diff <= 7.5) {
          units += 0.5
        } else {
          units += 1
        }
      }
      return units
    },
    unitsOfTimeFormatted(timestamp) {
      const units = this.unitsOfTime(timestamp)
      if (units <= 1) {
        return `${units} unit`
      }

      return `${units} units`
    },
    showSuccessToast(message) {
      this.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title: 'Success',
          icon: 'CheckIcon',
          variant: 'success',
          text: message,
        },
      }, 5000)
    },
    showErrorToast(message) {
      this.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title: 'Error',
          icon: 'XIcon',
          variant: 'danger',
          text: message,
        },
      }, 5000)
    },
    sanitizeResponses(questions, responses) {
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < questions.length; i++) {
        const obj = {}
        obj.question_id = questions[i].question_id
        if (responses[i]) {
          // eslint-disable-next-line default-case
          switch (questions[i].question.input_type.name) {
            case 'text':
              if (questions[i].question.text === 'Enter Patient Number') {
                this.documentTitle = responses[i]
              }
              obj.answer_text = responses[i]
              this.sanitizedResponses.push(obj)
              break
            case 'datetime':
              if (questions[i].question.text === 'Enter Appointment Scheduled Time') {
                this.documentDate = moment(responses[i]).format('l')
              }
              obj.answer_datetime = responses[i]
              this.sanitizedResponses.push(obj)
              break
            case 'time':
              obj.answer_time = responses[i]
              this.sanitizedResponses.push(obj)
              break
            case 'numeric':
              obj.answer_numeric = responses[i]
              this.sanitizedResponses.push(obj)
              break
            case 'single-select':
              obj.option_choice_id = responses[i].value
              this.sanitizedResponses.push(obj)
              break
            case 'multi-select-dropdown':
              if (Array.isArray(responses[i])) {
                // eslint-disable-next-line no-plusplus
                for (let k = 0; k < responses[i].length; k++) {
                  if (responses[i][k]) {
                    const obj2 = { ...obj }
                    obj2.option_choice_id = responses[i][k].value
                    if (responses[i][k].answer_time) {
                      obj2.answer_time = responses[i][k].answer_time
                    }
                    this.sanitizedResponses.push(obj2)
                  }
                }
              } else {
                obj.option_choice_id = responses[i].value
                if (responses[i].answer_time) {
                  obj.answer_time = responses[i].answer_time
                }
                this.sanitizedResponses.push(obj)
              }

              break
            case 'multi-select-modal':
              if (Array.isArray(responses)) {
                // eslint-disable-next-line no-plusplus
                for (let k = 0; k < responses.length; k++) {
                  if (responses[k]) {
                    const obj2 = { ...obj }
                    obj2.option_choice_id = responses[k].value
                    if (responses[k].answer_time) {
                      obj2.answer_time = responses[k].answer_time
                    }
                    this.sanitizedResponses.push(obj2)
                  } else {
                    obj.answer_time = responses[i]
                    this.sanitizedResponses.push(obj)
                  }
                }
              } else {
                obj.option_choice_id = responses[i].value
                if (responses[i].answer_time) {
                  obj.answer_time = responses[i].answer_time
                }
                this.sanitizedResponses.push(obj)
              }
              break
            case 'boolean':
              obj.answer_yn = responses[i].value
              this.sanitizedResponses.push(obj)
              break
            case 'textarea':
              obj.answer_text = responses[i]
              this.sanitizedResponses.push(obj)
              break
          }
        }
      }
    },
    initializeModels() {
      this.responses = []
      this.responseIds = {}
      this.serviceResponses = []
      const { responses } = this.selectedLoggableItemHistory
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < responses.length; i++) {
        const questionIndex = this.dailyLogQuestions.findIndex(q => q.question_id === responses[i].question_id)
        this.responseIds[questionIndex] = responses[i].id
        if (this.dailyLogQuestions[questionIndex].question.input_type.name === 'text' || this.dailyLogQuestions[questionIndex].question.input_type.name === 'textarea') {
          this.responses[questionIndex] = responses[i].answer_text
        } else if (this.dailyLogQuestions[questionIndex].question.input_type.name === 'datetime') {
          this.responses[questionIndex] = responses[i].answer_datetime
        } else if (this.dailyLogQuestions[questionIndex].question.input_type.name === 'boolean') {
          const selected = this.booleanOptions.find(o => o.value === responses[i].answer_yn)
          this.responses[questionIndex] = selected
        } else if (this.dailyLogQuestions[questionIndex].question.input_type.name === 'numeric') {
          this.responses[questionIndex] = responses[i].answer_numeric
        } else if (this.dailyLogQuestions[questionIndex].question.input_type.name === 'single-select') {
          const selected = this.dailyLogQuestions[questionIndex].question.option_group.option_choices.find(o => o.id === responses[i].option_choice_id)
          this.responses[questionIndex] = selected
        } else if (this.dailyLogQuestions[questionIndex].question.input_type.name === 'multi-select-dropdown') {
          const selected = this.dailyLogQuestions[questionIndex].question.option_group.option_choices.find(o => o.id === responses[i].option_choice_id)
          if (Array.isArray(this.responses[questionIndex])) {
            this.responses[questionIndex].push(selected)
          } else {
            this.responses[questionIndex] = []
            this.responses[questionIndex].push(selected)
          }
        } else if (this.dailyLogQuestions[questionIndex].question.input_type.name === 'multi-select-modal') {
          const selected = this.dailyLogQuestions[questionIndex].question.option_group.option_choices.find(o => o.id === responses[i].option_choice_id)
          if (responses[i].answer_time) {
            selected.answer_time = responses[i].answer_time
          }
          if (Array.isArray(this.serviceResponses)) {
            this.serviceResponses.push(selected)
          } else {
            this.serviceResponses = []
            this.serviceResponses.push(selected)
          }
        }
      }
    },
  },
  metaInfo() {
    return { title: this.$t('page_titles.edit_log_entry'), titleTemplate: 'DenTyme - %s' }
  },
}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
  @import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
